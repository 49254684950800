<template>
    <v-app>
        <app-header />
        <app-background class="d-print-none"/>
        <v-main>
            <nuxt-page />
        </v-main>
        <app-footer />
    </v-app>
</template>

<style lang="scss">
.page-enter-active,
.page-leave-active {
    transition: all 0.5s;
}

.page-enter-from,
.page-leave-to {
    opacity: 0;
    filter: blur(2px);
}
</style>
